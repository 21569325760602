<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div style="">
        <v-row>
            <v-col>
                <v-row class="sub-title-img-pc" align="center" justify="center">
                    <v-col
                            class="text-center"
                            cols="12"
                    >
                        <h1 class="text-h4 font-weight-bold white--text">
                            관리자 로그인
                        </h1>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-container class="my-8">
                    <v-row>
                        <v-col cols="3"></v-col>
                        <v-col cols="12" class="my-8" md="6">
                            <v-col cols="12" class="reservation-select-title mt-6">
                            </v-col>
                            <table class="resvUsrTable">
                                <colgroup>
                                    <col style="width:20%"/>
                                    <col style="width:80%"/>
                                </colgroup>
                                <tr>
                                    <th class="th">ID</th>
                                    <td class="td">
                                        <input id="resvNm" v-model="usr.usrId" v-on:keyup.enter="revConfirmBtnClick">

                                    </td>
                                </tr>
                                <tr>
                                    <th class="th">비밀번호</th>
                                    <td class="td">
                                        <input type="password" id="resvPw"
                                               v-model="usr.usrPw"
                                               v-on:keyup.enter="revConfirmBtnClick"
                                               style="width: 100%">

                                    </td>
                                </tr>
                            </table>
                            <v-row class="my-4">
                                <v-col cols="12" class="pr-3 pl-3">
                                    <div class="reservation-select-btn bg-darkblue" @click="revConfirmBtnClick()">
                                        로그인
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                </v-container>
            </v-col>
        </v-row>

    </div>
</template>

<script>
    export default {
        name: 'AdminLogin',
        components: {},
        data: () => ({
            usr: {
                usrId: "",
                usrPw: "",
            }


        }),
        created: function () {
            // 맨위로
            window.scrollTo(0,0);

        },
        methods: {
            revConfirmBtnClick(val) {

                    if (this.usr.usrId.length === 0) {
                        alert("ID를 입력해 주세요.");
                        return;
                    }

                    if (this.usr.usrPw.length === 0) {
                        alert("비밀번호를 입력해 주세요.");
                        return;
                    }

                    return this.$store.dispatch("admin/login", this.usr)
                        .then((resp) => {
                            setTimeout(() => {
                                this.$setCookie("AUTH","true","7");
                                //this.$router.push({path: "/admin/mainPage"});
                                location.href = '/admin/mainPage';
                            }, 300)
                        })
                        .catch((err) => {
                        })

            }
        },
        mounted() {
            this.$nextTick(() => {

            })
        },
        computed: {
        },
    }
</script>

<style>

</style>
